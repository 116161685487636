import "bootstrap";
import "materialize-css";
import { isEmail } from "validator"
import main from "../css/main.css";
import signin from "../css/signin.css";
import register from "../css/register/register.css";
import userProfile from "../css/userProfile/userprofile.css";
import bootstrap from "../css/bootstrap.css";
import materialize from "../css/materialize.css";
import footer from "../css/footers.css";
//import mdbCss from "../css/mdb/mdb.min.css";

//Javascript

//import mdb from "../js/mdb.min.js";


const data = {
  "gender": "male",
  "name": {
      "title": "mr",
      "first": "brad",
      "last": "gibson"
  },
  "countryCode": "IE",
  "postalCode": "93027",
  "email": "brad.gibson@example.com",
  "cell": "081-454-0666",
  "dob": "1993-07-20T09:44:18.674Z",
  "creditCardNumber": "4539415755005206",
  "book": {
      "title": "Harry Potter and the Deathly Hallows",
      "author": "Rowling, J. K.",
      "isbn": "9780545010221",
      "isbnVersion": "13"
  }
}


console.log(isEmail('brad.gibson@example.com'), "__________________________________DDADADADDTTATTATA VALIDATOR");

let $select1 = $( '#select1' ),
		$select2 = $( '#select2' ),
    $options = $select2.find( 'option' );
    
$select1.on( 'change', function() {
	$select2.html( $options.filter( '[data-dep="' + this.value + '"]' ) );
} ).trigger( 'change' );
document.addEventListener('DOMContentLoaded', () => {
  console.log("console.log from main");
});